
import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import '../styles/global.css';
import 'fontsource-open-sans';
import 'fontsource-oswald';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faBars } from '@fortawesome/free-solid-svg-icons';

import Header from "./header";
import Footer from "./footer";

library.add(fab, faCheckSquare, faBars)

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
					title
					menuLinks {
						name
						path
					}
					socialLinks {
						name
						icon
						path
					}
        }
      }
    }
  `)
  
  return (
    <div className="flex flex-col min-h-screen relative">
			<Header
        menuLinks={data.site.siteMetadata.menuLinks} 
				siteTitle={data.site.siteMetadata.title} 
			/>

        <main>
					{children}
				</main>

			<Footer socialLinks={data.site.siteMetadata.socialLinks} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
