import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Header = ({siteTitle, menuLinks}) => {

	return (
		<header className="bg-gradient-to-r from-blue-900 to-blue-800 sticky top-0 z-10 shadow-lg">
			<div className="container mx-auto px-8 py-4 flex items-center justify-between">
      	
        <AnchorLink className="text-white font-display text-2xl uppercase" to="/#hero">
					{siteTitle}
				</AnchorLink>
				<nav className="ml-auto">
						{
							menuLinks.map((link) => {
                return (
                  <AnchorLink key={link.name} className="text-white ml-4" to={link.path}>
									  {link.name}
								  </AnchorLink>
                );
              })
            }
				</nav>
		</div>
  </header>
	)
}

export default Header
