import React from 'react';
// import SocialList from '../components/social-nav';

const Footer = ({socialLinks}) => {

	return (
		<footer className="bg-gray-900 text-gray-200 text-center">
			<div className="container mx-auto py-8">
				{/* <SocialList socialLinks={socialLinks} /> */}
				<small className="mb-none">
					Copyright © {new Date().getFullYear()}. <span className="uppercase">Myrlin LLC.</span> All Rights Reserved.
				</small>
			</div>
	</footer>
	)
}

export default Footer